import { Box, BoxProps, Text } from "@chakra-ui/react";

const HighlightText = ({
  title,
  titleSize = '28px',
  children,
  ...rest
}: { title: string; titleSize?: string; children: React.ReactNode } & BoxProps) => {
  return (
    <Box {...rest}>
      <Text fontSize={titleSize} color={'white'}>{title}</Text>
      <Text color={'#718096'}>{children}</Text>
    </Box>
  );
};

export default HighlightText;
