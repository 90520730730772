import { Heading } from "@chakra-ui/react";
import React from "react";

const TermsOfUse = () => {
  return (
    <Heading>
      Termos de uso
    </Heading>
  );
};

export default TermsOfUse;