import {
  Box,
  Button,
  Text,
  Image,
  Stack,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { scroller } from "react-scroll";

interface AboutCardProps {
  id: string;
  title: string;
  description: string;
  children?: React.ReactNode;
  image: string;
  href?: string;
}

const AboutCard = ({
  id,
  title,
  description,
  children,
  image,
}: AboutCardProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const handleScrollTo = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <Stack
      direction={["column", "row"]}
      w={"100%"}
      px={[8, 0, 0, 16]}
      py={8}
      color={"white"}
      alignItems={"space-between"}>
      <Flex direction={["column"]} justify={"center"} maxW={[null, "50%"]}>
        <Text fontSize={["36px", "48px"]} fontWeight={"700"}>
          {title}
        </Text>
        <Text mb={8}>{description}</Text>
        <Box>{children}</Box>
        {!isMobile && (
          <Button
            as='a'
            borderRadius={"15px"}
            variant='outline'
            color={"white"}
            rightIcon={<IoIosArrowRoundForward size={"26px"} />}
            mt={8}
            onClick={() => handleScrollTo(id)}
            w={"200px"}>
            Saiba mais
          </Button>
        )}
      </Flex>
      <Flex direction={["column-reverse"]} align={"end"} w={[null, "50%"]}>
        {isMobile && (
          <Button
            as='a'
            w={"100%"}
            variant='outline'
            color={"white"}
            rightIcon={<IoIosArrowRoundForward size={"26px"} />}
            onClick={() => handleScrollTo(id)}
            mt={8}>
            Saiba mais
          </Button>
        )}
        <Image
          w={["100%", "auto"]}
          h={[null, "400px"]}
          borderRadius={"15px"}
          src={image}
        />
      </Flex>
    </Stack>
  );
};

export default AboutCard;
