import React, { useState, useEffect } from "react";
import { Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionText = motion(Text);

const FlippingText = ({ flippingText }: { flippingText: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === flippingText.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change text every 2 seconds

    return () => clearInterval(intervalId);
  }, [flippingText.length]);

  return (
    <MotionText
      as="span"
      display="inline-block"
      initial={{ y: "-25%", opacity: 0 }}
      animate={{ y: "0%", opacity: 1 }}
      exit={{ y: "100%", opacity: 0 }}
      transition={{ duration: 0.5}}
      color={'green.300'}
      key={currentIndex}
    >
      {flippingText[currentIndex]}
    </MotionText>
  );
};

export default FlippingText;
