import { Heading } from "@chakra-ui/react";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Heading>
      Politicas de privacidade
    </Heading>
  );
};

export default PrivacyPolicy;