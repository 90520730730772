import { useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ReviewsProps } from "..";
import ReviewCard from "./ReviewCard";

const Reviews = ({ reviews, color = "" }: ReviewsProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const index = isMobile ? 1 : 3;
  const indexPercent = 100 / index;

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? reviews.length - index : prev - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === reviews.length - index ? 0 : prev + 1));
  };

  return (
    <Box>
      <Flex direction={['column', 'row']} justifyContent={'space-between'} align={'center'} mb={8}>
        <Text
          fontSize={["40px", "64px"]}
          fontWeight={900}
          color={"white"}
          mt={8}
          mb={[4, 8]}
        >
          Depoimentos
        </Text>
        <Flex gap={4}>
          <IconButton
            icon={<FaArrowLeft />}
            onClick={prevSlide}
            aria-label="Previous Slide"
            bgColor={color}
            _focus={{ bgColor: color }}
            w={['100px']}
          />
          <IconButton
            icon={<FaArrowRight />}
            onClick={nextSlide}
            aria-label="Next Slide"
            bgColor={color}
            _focus={{ bgColor: color }}
            w={['100px']}
          />
        </Flex>
      </Flex>
      <Box width="full" overflow="hidden">
        <Flex
          transform={`translateX(-${currentIndex * indexPercent}%)`}
          transition="transform 0.5s ease-in-out"
        >
          {reviews.map((review) => (
            <ReviewCard
              name={review.name}
              color={color}
              comment={review.comment}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default Reviews;
