import { VStack, Text, Box, Flex, Button } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SectionProps {
  id?: string;
  title?: string;
  description?: string;
  bgColor?: string;
  phoneNumber?: string;
  message?: string;
  contactButton?: boolean;
  contactButtonColor?: string;
  px?: string[];
  children?: ReactNode;
}

const Section = ({
  id,
  title,
  description,
  bgColor = "#293533",
  phoneNumber,
  message,
  contactButton = false,
  contactButtonColor = "",
  px = ["4", "60px"],
  children,
}: SectionProps) => {
  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };

  return (
    <VStack
      id={id}
      bgColor={bgColor}
      w={"full"}
      alignItems={"start"}
      px={px}
      py={24}>
      <Flex
        direction={["column", "row"]}
        justifyContent={"space-between"}
        alignItems={"center"}
        w={"100%"}
        gap={8}>
        <Box>
          <Text fontSize={["40px", "64px"]} fontWeight={900} color={"white"}>
            {title}
          </Text>
          <Text color={"white"}>{description}</Text>
        </Box>
        {contactButton && (
          <Button
            borderRadius={3}
            h={50}
            as={"a"}
            w={["100%", "300px"]}
            onClick={handleClick}
            cursor={"pointer"}
            mr={[0, 20]}
            bgColor={contactButtonColor}>
            Agendar uma consulta
          </Button>
        )}
      </Flex>
      <Box w={"100%"}>{children}</Box>
    </VStack>
  );
};

export default Section;
