import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
// import Carousel from "src/components/Carousel";
import Section from "src/components/Section";
import AboutCard from "src/components/AboutCard";
import { professionals } from "src/utils/professionalsData";
import ProfessionalSection from "src/components/ProfessionalSection";
import ParallaxBox from "src/components/ParallaxBox";
import GradientDivider from "src/components/GradientDivider";
import PhotoSlider from "src/components/PhotoSlider";
import HighlightText from "src/components/HighlightText";
import Footer from "src/components/Footer";
import { scroller } from "react-scroll";
import AnimationBox from "src/components/AnimationBox";
import FlippingText from "src/components/FlippingText";

const Home = () => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const lastColor = professionals[professionals.length - 1].sectionColor;

  const avatars = [
    {
      name: "Ryan Florence",
      url: "https://bit.ly/ryan-florence",
    },
    {
      name: "Segun Adebayo",
      url: "https://bit.ly/sage-adebayo",
    },
    {
      name: "Kent Dodds",
      url: "https://bit.ly/kent-c-dodds",
    },
    {
      name: "Prosper Otemuyiwa",
      url: "https://bit.ly/prosper-baba",
    },
    {
      name: "Christian Nwamba",
      url: "https://bit.ly/code-beast",
    },
  ];

  const flippingTextContent = [
    "saúde",
    "forma Física",
    "beleza ",
    "qualidade de Vida ",
    "autoestima ",
    "alimentação ",
    "nutrição",
    "energia",
    "mente ",
    "rotina",
    "longevidade",
  ];

  const handleScrollTo = (element: string) => {
    scroller.scrollTo(element, {
      duration: 800,
      smooth: "easeInOutQuart",
    });
  };

  const message = encodeURIComponent(
    "Olá! Acessei o site e quero fazer uma consulta."
  );

  return (
    <VStack spacing={0}>
      <HStack
        id='inicio'
        h={["100dvh"]}
        w='100%'
        bgColor={"#293533"}
        bgImage={"bg-section-jr.png"}
        style={{
          backgroundSize: "contain",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}>
        <VStack w={["100%", "50%"]} px={["30px", "60px"]}>
          <Box color={"white"}>
            <Heading
              fontSize={["56px", "64px"]}
              fontWeight={900}
              lineHeight={1.2}>
              {/* Flipping text */}O melhor caminho para sua{" "}
              <FlippingText flippingText={flippingTextContent} />
            </Heading>
            <Text pt={4}>Seu bem-estar é a nossa prioridade</Text>
          </Box>
          <HStack pt={8} gap={5} alignItems={"start"} w='100%'>
            <Button
              colorScheme='green'
              px={12}
              borderRadius={3}
              h={50}
              onClick={() => {
                window.location.href = "https://wa.me/+5524998187883";
              }}>
              Comece agora
            </Button>
            <Button
              colorScheme='green'
              px={12}
              variant={"outline"}
              borderRadius={3}
              onClick={() => handleScrollTo("sobre-nos")}
              h={50}>
              Saiba mais
            </Button>
          </HStack>
        </VStack>

        {!isMobile && (
          <Box w={"1000px"} position={"relative"}>
            <PhotoSlider />
          </Box>
        )}
      </HStack>
      <Section
        id='sobre-nos'
        title='Sobre nós'
        description='Na nossa clínica, acreditamos que saúde é um estado de equilíbrio e bem-estar integral. Com uma abordagem holística, oferecemos serviços especializados de medicina, nutrição e educação física. Nossa equipe multidisciplinar está dedicada a oferecer um atendimento personalizado e de alta qualidade, focado nas necessidades individuais de cada paciente. Combinamos o conhecimento médico, a ciência da nutrição e práticas de atividade física para promover uma vida mais saudável e equilibrada. Seja para prevenir doenças, melhorar o desempenho ou alcançar objetivos específicos, estamos aqui para apoiar você em cada etapa da sua jornada de saúde.'>
        <Box mb={16} />
        <ParallaxBox imgUrl={"/assets/bg-nutri.jpg"}>
          <AboutCard
            id='nutricionista'
            title='Nutricionista'
            description='Com 7 anos de formado, 2 pós-graduações e 3 cursos profissionalizantes, experiência no mundo do esporte, experiência clínica e hospitalar. O Nutricionista Junior é o mais completo e dinâmico profissional na área de nutrição que você irá encontrar na região. A sua experiência é fundamental para garantir a segurança e a eficácia do seu tratamento, oferecendo orientação especializada e baseada em evidências científicas para promover sua saúde de forma segura e garantir excelentes resultados.'
            image={""}
          />
        </ParallaxBox>
        <ParallaxBox imgUrl={"img5.jpeg"}>
          <AboutCard
            id='medico'
            title='Medicina'
            description='Médica, pós graduanda em Endocrinologia e Metabologia, com foco em saúde da mulher, Giulia se preocupa em ofertar a cada paciente uma visão justa e amparada cientificamente para buscar os melhores resultados prezando sempre pela manutenção da saúde e bem estar.'
            image={""}
          />
        </ParallaxBox>
      </Section>
      {professionals.map((professional, index) => {
        const nextColor = professionals[index + 1]?.sectionColor;

        return (
          <>
            <Section
              id={professional.id}
              title={professional.sectionTitle}
              description={professional.sectionDescription}
              bgColor={professional.sectionColor}
              contactButton={professional.contactButton}
              phoneNumber={professional.phoneNumber}
              message={message}
              contactButtonColor={professional.contactButtonColor}>
              <ProfessionalSection
                profileImage={professional.profileImage}
                bookImages={professional.bookImages}
                highlightSection={professional.highlightSection}
                highlightSectionTitle={professional.contactButtonColor}
                reviews={professional.reviews}
                direction={
                  professional.direction as "row" | "row-reverse" | undefined
                }
                appointment={
                  professional.appointment ?? { description: "", image: "" }
                }
                reviewsColor={professional.reviewsColor ?? ""}
              />
            </Section>
            {nextColor && (
              <GradientDivider
                color1={professional.sectionColor}
                color2={nextColor}
                height={"300px"}
              />
            )}
          </>
        );
      })}
      <GradientDivider color1={lastColor} color2={"#293533"} />
      <Box
        background={"#293533"}
        bgImage={[null, "bgimg1.png"]}
        h={"100vh"}
        w={"full"}
        style={{
          backgroundSize: "contain",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}>
        <AnimationBox>
          <Flex
            direction={"column"}
            justifyContent={"center"}
            py={16}
            pl={[2, 24]}
            width={["100%", "50%"]}
            gap={8}>
            {/* <Text fontSize={["20px"]} color={["#718096"]}>
              ACOMPANHAMENTO PERSONALIZADO
            </Text> */}
            <HighlightText title='Acompanhamento personalizado' titleSize='48px'>
              Cuidar da sua saúde vai além de uma simples dieta, é sobre
              entender as necessidades do seu corpo e fornecer o suporte que ele
              precisa.
            </HighlightText>
            <SimpleGrid columns={[1, 2]} gap={[8]} w={"100%"}>
              <HighlightText title='Nutrição e Beleza'>
                <Text as={"span"} color={"white"} fontWeight={"700"}>
                  Sabemos que as necessidades nutricionais de cada mulher&nbsp;
                </Text>
                são únicas, especialmente quando o foco é saúde hormonal,
                performance física e mental. Nossa equipe oferece um
                acompanhamento voltado para otimizar seu desempenho em todas as
                áreas da vida, com planos alimentares que respeitam sua
                individualidade. Juntas, vamos fortalecer sua saúde, equilibrar
                hormônios e maximizar sua performance. Cuide de você de forma
                prática e personalizada.
              </HighlightText>
              <HighlightText title='Por que nos escolher?'>
                <Text as={"span"} color={"white"} fontWeight={"700"}>
                  Combinando 7 anos de experiência em nutrição,&nbsp;
                </Text>
                diversas especializações e atuação no mundo esportivo e clínico,
                o Nutricionista Junior traz uma abordagem científica e eficiente
                para cada paciente. Ao lado da médica Giulia, pós-graduanda em
                Endocrinologia e Metabologia com foco em saúde da mulher,
                oferecemos uma experiência completa e confiável, sempre
                priorizando sua saúde e bem-estar. Nosso diferencial está na
                personalização do atendimento e no cuidado com cada detalhe,
                garantindo tratamentos seguros e eficazes.
              </HighlightText>
              <HighlightText title=''>
                <Text as={"span"} color={"white"} fontWeight={"700"}>
                  Cuidar da sua saúde vai além de uma simples dieta,&nbsp;
                </Text>
                é sobre entender as necessidades do seu corpo e fornecer o
                suporte que ele precisa. Com uma abordagem especializada em
                saúde cardiovascular, equilíbrio hormonal e performance
                feminina, oferecemos um acompanhamento completo, focado em
                resultados sustentáveis. Nosso objetivo é proporcionar mais
                energia, bem-estar e confiança para você viver a sua melhor
                versão. Agende sua consulta personalizada e comece essa
                transformação hoje mesmo!
              </HighlightText>
              <HighlightText title=''>
                <Text as={"span"} color={"white"} fontWeight={"700"}>
                  Se você busca um tratamento que vai além das orientações
                  genéricas,&nbsp;
                </Text>
                aqui é o lugar certo. Oferecemos uma visão justa e baseada em
                evidências científicas, voltada para alcançar os melhores
                resultados sem comprometer sua saúde. Acreditamos que o
                equilíbrio entre alimentação e cuidados médicos é fundamental
                para você atingir suas metas e viver com mais qualidade de vida.
                Vamos juntas nessa jornada?
              </HighlightText>
            </SimpleGrid>
          </Flex>
        </AnimationBox>
      </Box>
      <Section id='faca-parte'>
        <AnimationBox>
          <Flex
            direction={["column", "row"]}
            justifyContent={"space-around"}
            h={"100vh"}
            alignItems={"center"}>
            <Flex
              direction={"column"}
              fontSize={["50px", "80px"]}
              fontWeight={"700"}
              color={"white"}
              justifyContent={"center"}
              w={["100%", "600px"]}
              h='500px'>
              <Text mb={5} lineHeight={1.2}>
                Alimente{" "}
                <Text as='span' color={"#8df785"}>
                  sua
                </Text>{" "}
                melhor versão
              </Text>
              <Stack direction={"row"} spacing={4} align={"center"}>
                <AvatarGroup>
                  {avatars.map((avatar) => (
                    <Avatar
                      key={avatar.name}
                      name={avatar.name}
                      src={avatar.url}
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      size={useBreakpointValue({ base: "md", md: "lg" })}
                      position={"relative"}
                      zIndex={2}
                      _before={{
                        content: '""',
                        width: "full",
                        height: "full",
                        rounded: "full",
                        transform: "scale(1.125)",
                        bgGradient: "linear(to-bl, green.800,green.200)",
                        position: "absolute",
                        zIndex: -1,
                        top: 0,
                        left: 0,
                      }}
                    />
                  ))}
                </AvatarGroup>
                <Text
                  fontFamily={"heading"}
                  fontSize={{ base: "4xl", md: "6xl" }}>
                  +
                </Text>
                <Flex
                  align={"center"}
                  justify={"center"}
                  fontFamily={"heading"}
                  fontSize={{ base: "sm", md: "lg" }}
                  bg={"gray.800"}
                  color={"white"}
                  rounded={"full"}
                  minWidth={useBreakpointValue({ base: "44px", md: "60px" })}
                  minHeight={useBreakpointValue({ base: "44px", md: "60px" })}
                  position={"relative"}
                  _before={{
                    content: '""',
                    width: "full",
                    height: "full",
                    rounded: "full",
                    transform: "scale(1.125)",
                    bgGradient: "linear(to-bl, orange.400,yellow.400)",
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                  }}>
                  Você
                </Flex>
              </Stack>
            </Flex>
            <Box w={["100%", "600px"]}>
              <Box bgColor={"#51746f"} borderRadius={"16px"} p={8}>
                <Text
                  fontSize={"58px"}
                  fontWeight={"700"}
                  mb={8}
                  color={"white"}>
                  Faça parte do time
                </Text>
                <Text mb={12} color={"white"}>
                  Quer atingir seus objetivos de saúde com um plano nutricional
                  personalizado? Faça parte do time e agende sua consulta agora!
                </Text>
                {/* <Input variant={"filled"} placeholder='Nome' mb={4} h={50} /> */}
                <Button
                  w={"100%"}
                  bgColor={"#7fbf7a"}
                  color={"white"}
                  borderRadius={3}
                  h={50}
                  onClick={() => {
                    window.location.href = "https://wa.me/+5524998187883";
                  }}>
                  Faça parte do time
                </Button>
              </Box>
            </Box>
          </Flex>
        </AnimationBox>
      </Section>
      <Footer />
    </VStack>
  );
};

export default Home;
