import {
  Avatar,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReviewCardProps } from "..";

const ReviewCard = ({ avatar, color = "", name, comment }: ReviewCardProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <Flex
      minW={isMobile ? "100%" : "33%"}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      pr={[0,4]}
    >
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        bgColor={color}
        w={["100%"]}
        p={8}
        boxShadow={"lg"}
        borderRadius={"6px"}
        color={"white"}
      >
        <Text fontSize={"16px"} mb={8}>
          {comment}
        </Text>
        <Avatar name={name} src={avatar} mb={2} />
        <Heading as={"h3"} fontSize={"lg"}>
          {name}
        </Heading>
      </Flex>
    </Flex>
  );
};

export default ReviewCard;
