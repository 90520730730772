import { motion, useAnimation, useScroll, useTransform } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

const MotionBox = motion(Box);

const AnimationBox = ({ children }: { children: React.ReactNode }) => {
  const controls = useAnimation();  
  const ref = useRef(null);  
  const [isVisible, setIsVisible] = useState(false);  

  // Intersection Observer to handle visibility  
  useEffect(() => {  
    const observer = new IntersectionObserver(  
      ([entry]) => {  
        if (entry.isIntersecting) {  
          setIsVisible(true);  
          controls.start({ opacity: 1 }); // Animate to opacity 1  
        } else {  
          setIsVisible(false);  
          controls.start({ opacity: 0 }); // Animate to opacity 0  
        }  
      },  
      { threshold: 0.2 } // Trigger when 20% of the component is visible  
    );  

    if (ref.current) {  
      observer.observe(ref.current);  
    }  

    // Cleanup the observer on unmount  
    return () => {  
      if (ref.current) {  
        observer.unobserve(ref.current);  
      }  
    };  
  }, [controls]);  


  return (
    <MotionBox
      ref={ref}
      initial={{ opacity: 0 }} // Initial state
      animate={controls}
      transition={{ duration: 0.5 }} // Animation duration
      opacity={isVisible ? 1 : 0} // Ensure opacity is adjusted for initial state
    >
      {children}
    </MotionBox>
  );
};

export default AnimationBox;
