import { Flex, Image, Text } from "@chakra-ui/react";
import ProfessionalGalleryPhotos from "./ProfessionalGalleryPhotos";
import Reviews from "./Reviews";
import AppointmentCard from "./AppointmentCard";
import AnimationBox from "../AnimationBox";

interface HighLightSectionProps {
  title: string;
  subtitle: string;
  description: string;
}
export interface ReviewCardProps {
  avatar?: string;
  name: string;
  color?: string;
  comment: string;
}

export interface ReviewsProps {
  reviews: ReviewCardProps[];
  color?: string;
}

export interface AppointmentCardProps {
  description: string;
  image: string;
  bgColor?: string;
  href?: string;
}

interface ProfileSectionProps {
  profileImage: string;
  direction?: "row" | "row-reverse" | undefined;
  highlightSectionTitle: string;
  appointment: AppointmentCardProps;
  bookImages: string[];
  reviews: ReviewCardProps[];
  reviewsColor: string;
  highlightSection: HighLightSectionProps[];
}

const ProfileSection = ({
  profileImage,
  direction = "row",
  highlightSectionTitle,
  highlightSection,
  appointment,
  reviews,
  reviewsColor,
}: ProfileSectionProps) => {
  return (
    <Flex direction="column" w={"100%"}>
      <AnimationBox>
        <Flex
          direction={["column", "column", "column", "column", direction]}
          justifyContent={["center", "space-around"]}
          pt={16}
          w={"100%"}
          h={"100vh"}
          gap={8}
          bgImage={"bg-section-jr.png"}
          style={{
            backgroundSize: "contain",
            backgroundPosition: "left",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Image
            src={profileImage}
            alignSelf={"center"}
            w={["100%", "100%", "100%", "30%"]}
            borderRadius={"30px"}
            maxW={[null, "600px"]}
          />

          <ProfessionalGalleryPhotos />
        </Flex>
      </AnimationBox>
      <AnimationBox>

      <Flex
        justifyContent={["center", "space-around"]}
        direction={["column", "row"]}
        >
        {highlightSection.map((highlight) => (
          <Flex
          direction={"column"}
          color={"white"}
          py={[8, 16]}
          px={[4, 4, 8, 12, 20]}
          >
            <Text
              fontSize={"40px"}
              fontWeight={"700"}
              textAlign={["center", null]}
              color={highlightSectionTitle}
              >
              {highlight.title}
            </Text>
            <Text
              textAlign={["center", null]}
              fontSize={"20px"}
              fontWeight={"700"}
              >
              {highlight.subtitle}
            </Text>
            <Text
              textAlign={["center", null]}
              fontSize={"16px"}
              fontWeight={"500"}
              >
              {highlight.description}
            </Text>
          </Flex>
        ))}
      </Flex>
        </AnimationBox>
      {reviews.length > 0 && (
        <AnimationBox>
          <Reviews reviews={reviews} color={reviewsColor} />
        </AnimationBox>
      )}
      {appointment.description && (
        <AnimationBox>
          <AppointmentCard
            description={appointment.description}
            image={appointment.image}
            bgColor={appointment.bgColor}
          />
        </AnimationBox>
      )}
    </Flex>
  );
};

export default ProfileSection;
