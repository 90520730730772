import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      100: "#F5F5F5",
      200: "#F2F7FF",
      //nutri
      300: "#7FBF7A",
      800: "#51746F",
      900: "#293533",
    },
    text: {
      100: "#FFFFFF",
      200: "#EEEFF4",
      300: "#808080",
      500: "#4F4F4F",
      900: "#303030",
    },
  },
  fonts: {
    heading: 'Inter, sans-serif',
    body: `'Poppins', sans-serif`,
  },
});
