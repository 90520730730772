import { Box, useBreakpointValue } from "@chakra-ui/react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

interface StickyImageProps {
  imgUrl: string;
}

interface OverlayCopyProps {
  children: React.ReactNode;
}

interface ParallaxBoxProps extends StickyImageProps, OverlayCopyProps {}

const MotionBox = motion(Box);
const IMG_PADDING = 100;

const ParallaxBox = ({ imgUrl, children }: ParallaxBoxProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  
  return (
    <div
      style={{
        paddingLeft: isMobile ? 0 : IMG_PADDING,
        paddingRight: isMobile ? 0 : IMG_PADDING,
      }}
    >
      <Box position={"relative"} height={"150vh"}>
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy children={children} />
      </Box>
    </div>
  );
};

const StickyImage = ({ imgUrl }: StickyImageProps) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <MotionBox
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center 40%",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        overflow: "hidden",
        scale,
        filter: "brightness(50%)", 
      }}
      ref={targetRef}
      position={"sticky"}
      zIndex={0}
      borderRadius={"3xl"}
    >
      <MotionBox
        position={"absolute"}
        inset={0}
        bgColor={"blackAlpha.800"}
        style={{
          opacity,
        }}
      />
    </MotionBox>
  );
};

const OverlayCopy = ({ children }: OverlayCopyProps) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <MotionBox
      style={{
        y,
        opacity,
        left: 0,
        top: 0,
      }}
      ref={targetRef}
      position={"absolute"}
      h={"100vh"}
      w={"full"}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {children}
    </MotionBox>
  );
};

export default ParallaxBox;
