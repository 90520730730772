import React from "react";
import { jrsaSlides } from "src/utils/slides";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";

const PhotoSlider = () => {
  const [index, setIndex] = React.useState(0);
  const [autoplay] = React.useState(true);
  const [delay] = React.useState(5000);

  const updateIndex = ({ index: current }: { index: number }) =>
    setIndex(current);
  return (
    <Lightbox
      index={index}
      slides={jrsaSlides}
      plugins={[Inline, Slideshow]}
      slideshow={{ autoplay, delay }}
      on={{
        view: updateIndex,
      }}
      carousel={{
        padding: 0,
        spacing: 0,
        imageFit: "cover",
      }}
      inline={{
        style: {
          width: "100%",
          height: "100vh",
          margin: "0 auto",
        },
      }}
    />
  );
};

export default PhotoSlider;
