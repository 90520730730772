import Lightbox from "yet-another-react-lightbox";

import { RowsPhotoAlbum } from "react-photo-album";
import React from "react";
import { jrsaSlides } from "src/utils/slides";
import { Box } from "@chakra-ui/react";

export default function ProfessionalGalleryPhotos() {
  const [index, setIndex] = React.useState(-1);
  return (
    <Box
      w={["100%", "100%", "100%", "800px"]}
      overflow={"auto"}
      overflowY={"scroll"}
      style={{
        scrollbarWidth: "none",
      }}>
      <RowsPhotoAlbum
        photos={jrsaSlides}
        targetRowHeight={400}
        onClick={({ index: current }) => setIndex(current)}
      />

      <Lightbox
        index={index}
        slides={jrsaSlides}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </Box>
  );
}
