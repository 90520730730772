import { Box, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import React from "react";
interface Props {
  href: string;
  children: React.ReactNode;
}

export const NavLink = (props: Props) => {
  const navigate = useNavigate();
  const { children, href } = props;

  const handleScrollTo = (element: string) => {
    if (window.location.pathname !== "/") {
      navigate("/", { replace: true });
      setTimeout(() => {
        scroller.scrollTo(element, {
          duration: 800,
          smooth: "easeInOutQuart",
        });
      }, 0);
    } else {
      scroller.scrollTo(element, {
        duration: 800,
        smooth: "easeInOutQuart",
      });
    }
  };

  return (
    <Box
      as={"a"}
      borderBottom={"2px"}
      borderBottomColor={"transparent"}
      color={'white'}
      cursor={"pointer"}
      _hover={{
        borderBottom: "2px",
        borderBottomColor: "gray.500",
        transition: "all 0.3s ease-in-out",
      }}
      onClick={() => handleScrollTo(href)}>
      {children}
    </Box>
  );
};
