import { css, Global } from "@emotion/react";

const ScrollBar = () => (
  <Global
    styles={css`
      body::-webkit-scrollbar {
        width: 0.5em;
      }

      body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #293533;
      }

      body::-webkit-scrollbar-thumb {
        background-color: #293530;
      }
    `}
  />
);

export default ScrollBar;