import { Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { NavLink } from "../Navbar/NavLink";
import { FaFacebook, FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  const Links = [
    {
      href: "inicio",
      name: "Início",
    },
    {
      href: "sobre-nos",
      name: "Quem somos",
    },
    {
      href: "nutricionista",
      name: "Nutricionista",
    },
    {
      href: "medico",
      name: "Médico",
    },
    {
      href: "personal",
      name: "Personal Trainer",
    },
  ];

  return (
    <Flex
      direction={"column"}
      gap={[8, null]}
      bg={"#293533"}
      bgImage={"footer.png"}
      h={["full", "100vh"]}
      w={"full"}
      style={{
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      p={[0, 24]}
      pb={[16, 0]}>
      <Flex
        h={[null, "25%"]}
        direction={"column"}
        justify={"center"}
        align={"center"}
        gap={[4, 8]}
        px={[4, 24]}>
        <Image src='logo.svg' />
        <Text textAlign={"center"} color={"white"}>
          Cuidando da sua saúde com ciência, dedicação e resultados que
          transformam.
        </Text>
      </Flex>
      <Flex
        h={[null, "25%"]}
        direction={["column", "row"]}
        justify={"center"}
        align={"center"}
        gap={[4, 8]}
        px={[4, 24]}>
        {Links.map((link) => (
          <NavLink key={link.name} href={link.href}>
            <Text fontSize={"2xl"} color={"white"}>
              {link.name}
            </Text>
          </NavLink>
        ))}
      </Flex>
      <Flex
        h={[null, "25%"]}
        direction={["row"]}
        justify={"center"}
        align={"center"}
        gap={[4, 16]}
        px={[4, 24]}>
        <IconButton
          variant={"ghost"}
          as='a'
          href='#'
          color={"white"}
          icon={<FaFacebook size={"md"} />}
          aria-label={"Facebook"}
        />
        <IconButton
          variant={"ghost"}
          as='a'
          href='#'
          color={"white"}
          icon={<FaYoutube size={"md"} />}
          aria-label={"Youtube"}
        />
        <IconButton
          variant={"ghost"}
          as='a'
          href='#'
          color={"white"}
          icon={<FaInstagram size={"md"} />}
          aria-label={"Instagram"}
        />
        <IconButton
          variant={"ghost"}
          as='a'
          href='#'
          color={"white"}
          icon={<FaLinkedin size={"md"} />}
          aria-label={"Linkedin"}
        />
      </Flex>
      <Flex
        h={[null, "25%"]}
        direction={["row"]}
        justify={"center"}
        align={"center"}
        gap={[4, 16]}
        px={[4, 24]}>
        <Text as='a' _hover={{ cursor: "pointer" }} color={"white"}>
          Copyright© 2024. Sá corp
        </Text>
        <Text as='a' _hover={{ cursor: "pointer" }} color={"white"}>
          Políticas de Privacidade
        </Text>
        <Text as='a' _hover={{ cursor: "pointer" }} color={"white"}>
          Termos e Cookies
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
