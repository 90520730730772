import React from "react";
import { Flex } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Home from "./pages/Home";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
  return (
    <Flex direction={"column"}>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/politica-de-privacidade' element={<PrivacyPolicy />} />
        <Route path='/termos-de-uso' element={<TermsOfUse />} />
      </Routes>
    </Flex>
  );
}

export default App;
