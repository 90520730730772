import { Box } from "@chakra-ui/react";

interface GradientDividerProps {
  color1: string;
  color2: string;
  height?: string | string[];
}

const GradientDivider = ({ color1, color2, height='200px' }: GradientDividerProps) => {
  return (
    <Box
      bgGradient={`linear(${color1}, ${color2})`}
      w="100%"
      h={height}
      blur={"90px"}
    />
  );
};

export default GradientDivider;
